<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Insurance
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          @click="addInsurance()"
          class="
          flex
          items-center
          bg-green-200
          border
          hover:bg-green-300
          border-green-400
          hover:border-green-500
          hover:shadow
          active:shadow-inner
          transition
          duration-300
          text-secondary
          rounded-md
          px-2
          py-1
        "
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M12 5v14M5 12h14" />
          </svg>
          <span>Add Insurance</span>
        </button>
      </is-authorized>
    </div>

    <div class="flex flex-wrap">
      <vue-good-table
        v-if="insurances.length > 0"
        class="w-full"
        styleClass="vgt-table striped"
        mode="remote"
        :rows="insurances"
        :columns="columns"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'is_active'">
            <span
              class="px-2 rounded-lg"
              :class="{
                'bg-yellow-200 text-yellow-700': props.row.is_active == false,
                'bg-green-200 text-green-700': props.row.is_active == true,
              }"
              >{{ props.row.is_active ? "Active" : "Expired" }}</span
            >
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <is-authorized :permissions="['manage:fleets', 'delete:fleets']">
              <button
                type="button"
                @click="deleteInsurance(props.row)"
                class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
                </svg>
                <span></span>
              </button>
            </is-authorized>
            <is-authorized :permissions="['manage:fleets', 'update:fleets', 'read:fleets']">
              <button
                type="button"
                @click="editInsurance(props.row)"
                class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                </svg>
                <span></span>
              </button>
            </is-authorized>
          </span>

          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>
      <p class="mx-auto my-16" v-if="insurances.length == 0">
        There are no Insurances for this Fleet.
      </p>
    </div>

    <portal to="overlay-outlet">
      <panel
        :showing="addEditInsurancePanelOpen"
        @close="addEditInsurancePanelOpen = false"
        :title="editingInsurance.insurance_id ? 'Edit Insurance' : 'Add Insurance'"
      >
        <AddEditInsurance :insurance="editingInsurance" @complete="saveInsurance" @delete="deleteInsurance" />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert :showingConfirm="confirmAlert.showing" :message="confirmAlert.message" @complete="confirmAlert.resultFunction" />
    </portal>
  </div>
</template>

<script>
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
import dateRangeOverlap from "@/utils/dateRangeOverlap";
const AddEditInsurance = () => import("@/components/Fleets/Edit/AddEditInsurance.vue");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "Insurance",
  components: {
    Notification,
    ConfirmAlert,
    Panel,
    AddEditInsurance,
    IsAuthorized,
  },
  props: {
    insurances: Array,
  },
  data() {
    return {
      addEditInsurancePanelOpen: false,
      editingInsurance: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        {
          label: "Company Name",
          field: "company_name",
          width: "20%",
        },
        {
          label: "Policy Number",
          field: "policy_number",
          width: "20%",
        },
        {
          label: "Contract Begin",
          field: "contract_begin",
          // type: "date",
          width: "20%",
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment(v).format("DD/MM/YYYY");
          },
        },
        {
          label: "Contract End",
          field: "contract_end",
          width: "20%",

          // type: "date",
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment(v).format("DD/MM/YYYY");
          },
        },
        {
          label: "Status",
          field: "is_active",
          width: "10%",
          tdClass: this.tblClassCenterAlign,
          thClass: this.tblClassCenterAlign,
        },
        {
          field: "actions",
          type: "custom",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  computed: {
    fleet_id: function() {
      return this.$store.state.lastSelectedFleet;
    },
  },
  methods: {
    tblClassLeftAlign(row) {
      return "vgt-left-align";
    },
    tblClassCenterAlign(row) {
      return "vgt-center-align";
    },
    addInsurance: function() {
      this.editingInsurance = {};
      this.addEditInsurancePanelOpen = true;
    },
    handleEditInsurance: function(selectedRow) {
      let insuranceData = selectedRow.row;
      this.editInsurance(insuranceData);
      /*this.$router.push({
              name: "AdEditInsurance",
              params: { insurance: insuranceData },
            });*/
    },
    editInsurance: function(insurance) {
      this.editingInsurance = { ...insurance };
      this.addEditInsurancePanelOpen = true;
    },
    saveInsurance: async function(insurance) {
      // Ensure insurance has mandatory fields filled out
      let msg;
      if (!insurance.company_name) {
        msg = "Please company name.";
      } else if (!insurance.policy_number) {
        msg = "Please provide the policy number";
      } else if (!insurance.level_of_cover) {
        msg = "Please provide level of cover";
      } else if (!insurance.contract_begin) {
        msg = "Please provide contract begin date";
      } else if (!insurance.contract_end) {
        msg = "Please provide contract end date";
      } else if (!insurance.reminder_date) {
        msg = "Please provide reminder date";
      } else if (
        this.$validator.isBefore(
          insurance.contract_end instanceof Date ? insurance.contract_end.toDateString() : insurance.contract_end,
          insurance.contract_begin instanceof Date ? insurance.contract_begin.toDateString() : insurance.contract_begin
        )
      ) {
        msg = "Contract begin must be less than contract end date.";
      } else if (
        this.$validator.isBefore(
          insurance.contract_end instanceof Date ? insurance.contract_end.toDateString() : insurance.contract_end,
          insurance.reminder_date instanceof Date ? insurance.reminder_date.toDateString() : insurance.reminder_date
        )
      ) {
        msg = "Reminder date date must be greater than contract end date.";
      }

      this.insurances.forEach(function(item) {
        if (item.insurance_id != insurance.insurance_id) {
          if (dateRangeOverlap(new Date(item.contract_begin), new Date(item.contract_end), insurance.contract_begin, insurance.contract_end)) {
            msg = "Contract date overlap with other insurances.";
          }
        }
      });
      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.addEditInsurancePanelOpen = false;

      try {
        insurance.is_active = true;
        if (insurance.contract_end) {
          insurance.is_active = new Date(insurance.contract_end) >= new Date();
        }
        if (!insurance.insurance_id) {
          let createResult = await this.FleetService.createFleetInsurance(this.fleet_id, insurance);
          insurance.insurance_id = createResult.data.insurance_id;
          this.insurances.push(insurance);
        } else {
          await this.FleetService.updateFleetInsurance(this.fleet_id, insurance.insurance_id, insurance);
          let idx = this.$_.findIndex(this.insurances, (c) => c.insurance_id == insurance.insurance_id);
          this.insurances.splice(idx, 1, insurance);
        }

        this.$emit("complete");
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Details Saved",
                  close: onClose,
                },
              },
              "Insurance details saved"
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (err) {
        console.error(err);

        let msg = !insurance.insurance_id ? "There was a problem creating the new Insurance" : "There was a problem updating the Insurance";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There was a problem updating the Insurance"
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    handlePromoteInsuranceToCurrent: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.confirmAlert.data.is_active = true;
          await this.saveInsurance(this.confirmAlert.data);
          this.insurances.forEach((c) => {
            if (c.insurance_id !== this.confirmAlert.data.insurance_id) {
              c.is_active = false;
            }
          });
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem updating the Insurance"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    promoteInsuranceToCurrent: function(insurance) {
      this.confirmAlert = {
        resultFunction: this.handlePromoteInsuranceToCurrent,
        message: `Change '${insurance.policy_number}' as current insurance?`,
        showing: true,
        data: insurance,
      };
    },
    handleDeleteInsurance: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.FleetService.deleteInsurance(this.fleet_id, this.confirmAlert.data.insurance_id);
          let idx = this.$_.findIndex(this.insurances, (c) => {
            return c.insurance_id == this.confirmAlert.data.insurance_id;
          });

          this.$delete(this.insurances, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Insurance Deleted",
                    close: onClose,
                  },
                },
                "Successfully deleted insurance"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (err) {
          console.error(err);

          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the Insurance"
              );
            },
            {
              position: "top-right",
            }
          );
        }
        this.addEditInsurancePanelOpen = false;
        this.isBusy = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteInsurance: function(insurance) {
      // If we're deleting a insurance that hasn't been saved yet
      if (String(insurance.insurance_id).startsWith("pending")) {
        let idx = this.$_.findIndex(this.insurances, (c) => {
          return c.insurance_id == insurance.insurance_id;
        });

        this.$delete(this.insurances, idx);
        return;
      }

      this.confirmAlert = {
        resultFunction: this.handleDeleteInsurance,
        message: `Are you sure you wish to delete '${insurance.policy_number}' This cannot be undone.`,
        showing: true,
        data: insurance,
      };
    },
  },
};
</script>
